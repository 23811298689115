import {
  Banner,
  Card,
  DatePicker,
  FormLayout,
  Heading,
  Modal,
  Select,
  Spinner,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { useAdmin } from "context/admin";
import React, { useState } from "react";
import { STORE_PROVIDER } from "shared/constants";
import {
  calculateDeliveryRate,
  createManualDelivery,
  scheduleManualDelivery,
} from "util/manualDeliveries";

export default function ManualDeliveryModal({ open, onClose, onSuccess }) {
  const admin = useAdmin();
  const [formValues, setFormValues] = useState({
    carrier_provider: STORE_PROVIDER,
    total_value_cents: "",
    items_notes: "",
    delivery_date: new Date(),
    start_time: "09:00",
    status: "pending",
    customer_first_name: "",
    customer_last_name: "",
    customer_email: "",
    customer_phone: "",
    address1: "",
    address2: "",
    city: "",
    postal_code: "",
    province: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedDate, setSelectedDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  // New state variables for the confirmation step
  const [currentStep, setCurrentStep] = useState("form"); // "form" or "confirmation"
  const [deliveryRate, setDeliveryRate] = useState(null);
  const [isLoadingRate, setIsLoadingRate] = useState(false);

  const handleChange = (field) => (value) => {
    setFormValues({ ...formValues, [field]: value });
    // Clear validation error for this field when user changes it
    if (validationErrors[field]) {
      setValidationErrors({ ...validationErrors, [field]: null });
    }
  };

  const handleDateChange = (range) => {
    setSelectedDate(range);

    // Make sure we're setting a valid date object
    if (range && range.start instanceof Date) {
      setFormValues({ ...formValues, delivery_date: range.start });
    }

    if (validationErrors.delivery_date) {
      setValidationErrors({ ...validationErrors, delivery_date: null });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formValues.total_value_cents || formValues.total_value_cents === "0") {
      errors.total_value_cents = "Total value is required";
    }

    if (!formValues.delivery_date) {
      errors.delivery_date = "Delivery date is required";
    }

    if (!formValues.start_time) {
      errors.start_time = "Start time is required";
    }

    // Basic address validation
    if (!formValues.address1) {
      errors.address1 = "Address is required";
    }

    if (!formValues.city) {
      errors.city = "City is required";
    }

    if (!formValues.postal_code) {
      errors.postal_code = "Postal code is required";
    }

    if (!formValues.province) {
      errors.province = "Province is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Calculate end time as 2 hours after start time
  const calculateEndTime = (startTime) => {
    if (!startTime) return "";

    try {
      // Parse the start time
      const [hours, minutes] = startTime.split(":").map(Number);

      // Add 2 hours
      let endHours = hours + 2;

      // Handle overflow (if end time goes past midnight)
      if (endHours >= 24) {
        endHours = endHours - 24;
      }

      // Format the end time
      return `${endHours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    } catch (error) {
      console.error("Error calculating end time:", error);
      return "";
    }
  };

  // Function to prepare the delivery payload
  const prepareDeliveryPayload = () => {
    // Convert dollar amount to cents
    const totalValueCents = Math.round(
      parseFloat(formValues.total_value_cents || "0") * 100
    );

    // Format customer name
    const customerName = `${formValues.customer_first_name} ${formValues.customer_last_name}`.trim();

    // Format full address
    const formattedAddress = [
      formValues.address1,
      formValues.address2,
      formValues.city,
      formValues.province,
      formValues.postal_code,
    ]
      .filter(Boolean)
      .join(", ");

    // Ensure delivery_date is properly formatted
    let deliveryDate;

    if (formValues.delivery_date instanceof Date) {
      // Format as YYYY-MM-DD
      const year = formValues.delivery_date.getFullYear();
      const month = String(formValues.delivery_date.getMonth() + 1).padStart(
        2,
        "0"
      ); // months are 0-indexed
      const day = String(formValues.delivery_date.getDate()).padStart(2, "0");
      deliveryDate = `${year}-${month}-${day}`;
    } else if (typeof formValues.delivery_date === "string") {
      deliveryDate = formValues.delivery_date;
    } else {
      // Fallback to today's date
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      deliveryDate = `${year}-${month}-${day}`;
    }

    // Get the client's timezone
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create the payload directly matching the server's expected parameters
    return {
      delivery_date: deliveryDate,
      start_time: formValues.start_time,
      total_value_cents: totalValueCents,
      items_notes: formValues.items_notes,
      carrier_provider: formValues.carrier_provider,
      status: formValues.status,
      customer_name: customerName,
      customer_email: formValues.customer_email,
      customer_phone: formValues.customer_phone,
      address: formattedAddress,
      address_details: {
        address1: formValues.address1,
        address2: formValues.address2,
        city: formValues.city,
        postal_code: formValues.postal_code,
        province: formValues.province,
      },
      client_timezone: clientTimezone,
    };
  };

  // Function to move to the confirmation step
  const handleProceedToConfirmation = async () => {
    // Validate form before proceeding
    if (!validateForm()) {
      setError("Please fix the validation errors before continuing");
      return;
    }

    setIsLoadingRate(true);
    setError(null);

    try {
      // Prepare the payload for rate calculation
      const payload = prepareDeliveryPayload();

      // Call the backend to calculate the actual delivery rate
      const rateResponse = await calculateDeliveryRate(
        admin.selectedShopId,
        payload
      );

      if (rateResponse && rateResponse.success) {
        // Convert rate from cents to dollars for display
        const calculatedRate = (rateResponse.rate.total_price || 0) / 100;
        setDeliveryRate({
          amount: calculatedRate,
          details: rateResponse.rate,
          location: rateResponse.location,
        });
        setCurrentStep("confirmation");
      } else {
        throw new Error(
          rateResponse.error || "Failed to calculate delivery rate"
        );
      }
    } catch (err) {
      console.error("Error calculating delivery rate:", err);
      setError(err.message || "Failed to calculate delivery rate");
    } finally {
      setIsLoadingRate(false);
    }
  };

  // Function to go back to the form step
  const handleBackToForm = () => {
    setCurrentStep("form");
    setError(null);
  };

  // Function to create and schedule the delivery
  const handleCreateAndSchedule = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      const payload = prepareDeliveryPayload();
      console.log("Creating and scheduling delivery with payload:", payload);

      // Create the manual delivery
      const response = await createManualDelivery(
        admin.selectedShopId,
        payload
      );
      console.log("Manual delivery created:", response);

      // Schedule the delivery immediately
      if (response && response.id) {
        console.log("Scheduling delivery with ID:", response.id);
        await scheduleManualDelivery(admin.selectedShopId, response.id);
      }

      onClose();
      onSuccess();
    } catch (err) {
      console.error("Error creating and scheduling delivery:", err);
      setError(err.message || "Failed to create and schedule delivery");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Get available carriers for the shop
  const carrierOptions = [
    { label: "Default Store Carrier", value: STORE_PROVIDER },
  ];

  if (admin.selectedShop && admin.selectedShop.carrier) {
    carrierOptions.push({
      label: admin.selectedShop.carrier.name || admin.selectedShop.carrier,
      value: admin.selectedShop.carrier,
    });
  }

  // Section separator style
  const sectionStyle = {
    borderTop: "1px solid #dfe3e8",
    paddingTop: "16px",
    marginTop: "16px",
  };

  // Calculate the current end time for display
  const currentEndTime = calculateEndTime(formValues.start_time);

  // Format customer name for display
  const customerName = `${formValues.customer_first_name} ${formValues.customer_last_name}`.trim();

  // Format address for display
  const formattedAddress = [
    formValues.address1,
    formValues.address2,
    formValues.city,
    formValues.province,
    formValues.postal_code,
  ]
    .filter(Boolean)
    .join(", ");

  // Render the form step
  const renderFormStep = () => (
    <FormLayout>
      {/* Carrier Selection */}
      <Select
        label="Carrier"
        options={carrierOptions}
        value={formValues.carrier_provider}
        onChange={handleChange("carrier_provider")}
      />

      {/* Customer Information Section */}
      <div style={sectionStyle}>
        <Heading>Customer Information</Heading>
      </div>
      <FormLayout.Group>
        <TextField
          label="First Name"
          value={formValues.customer_first_name}
          onChange={handleChange("customer_first_name")}
          autoComplete="off"
        />
        <TextField
          label="Last Name"
          value={formValues.customer_last_name}
          onChange={handleChange("customer_last_name")}
          autoComplete="off"
        />
      </FormLayout.Group>

      <TextField
        label="Email"
        type="email"
        value={formValues.customer_email}
        onChange={handleChange("customer_email")}
        autoComplete="off"
      />

      <TextField
        label="Phone Number"
        type="tel"
        value={formValues.customer_phone}
        onChange={handleChange("customer_phone")}
        autoComplete="off"
      />

      {/* Address Fields */}
      <TextField
        label="Address Line 1"
        value={formValues.address1}
        onChange={handleChange("address1")}
        autoComplete="off"
        error={validationErrors.address1}
        required
      />

      <TextField
        label="Address Line 2 (Optional)"
        value={formValues.address2}
        onChange={handleChange("address2")}
        autoComplete="off"
      />

      <FormLayout.Group>
        <TextField
          label="City"
          value={formValues.city}
          onChange={handleChange("city")}
          autoComplete="off"
          error={validationErrors.city}
          required
        />

        <TextField
          label="Province/State"
          value={formValues.province}
          onChange={handleChange("province")}
          autoComplete="off"
          error={validationErrors.province}
          required
        />
      </FormLayout.Group>

      <TextField
        label="Postal/ZIP Code"
        value={formValues.postal_code}
        onChange={handleChange("postal_code")}
        autoComplete="off"
        error={validationErrors.postal_code}
        required
      />

      {/* Order Information Section */}
      <div style={sectionStyle}>
        <Heading>Order Information</Heading>
      </div>
      <TextField
        label="Total Order Value ($)"
        type="number"
        value={formValues.total_value_cents}
        onChange={handleChange("total_value_cents")}
        autoComplete="off"
        helpText="Enter the total value of items in this delivery"
        error={validationErrors.total_value_cents}
        required
      />

      <TextField
        label="Items Notes"
        value={formValues.items_notes}
        onChange={handleChange("items_notes")}
        multiline={3}
        autoComplete="off"
        helpText="Enter any notes about the items in this delivery"
      />

      {/* Delivery Window Section */}
      <div style={sectionStyle}>
        <Heading>Delivery Window</Heading>
      </div>

      <DatePicker
        month={selectedDate.start.getMonth()}
        year={selectedDate.start.getFullYear()}
        onChange={handleDateChange}
        selected={selectedDate}
        label="Delivery Date"
        error={validationErrors.delivery_date}
      />

      <TextField
        label="Start Time"
        type="time"
        value={formValues.start_time}
        onChange={handleChange("start_time")}
        error={validationErrors.start_time}
        required
        helpText="Delivery window will be 2 hours from this time"
      />

      <div>
        <TextStyle variation="subdued">
          Delivery Window: {formValues.start_time} - {currentEndTime} (2 hour
          window)
        </TextStyle>
      </div>

      {error && (
        <Banner status="critical">
          <p>{error}</p>
        </Banner>
      )}
    </FormLayout>
  );

  // Render the confirmation step
  const renderConfirmationStep = () => (
    <Stack vertical spacing="loose">
      <TextContainer>
        <Heading>Confirm Delivery Details</Heading>
        <p>Please review the delivery details before scheduling.</p>
      </TextContainer>

      <Card sectioned>
        <Stack vertical spacing="tight">
          <TextContainer>
            <Heading>Customer Information</Heading>
            <p>
              <strong>Name:</strong> {customerName || "Not provided"}
            </p>
            <p>
              <strong>Email:</strong>{" "}
              {formValues.customer_email || "Not provided"}
            </p>
            <p>
              <strong>Phone:</strong>{" "}
              {formValues.customer_phone || "Not provided"}
            </p>
            <p>
              <strong>Address:</strong> {formattedAddress}
            </p>
          </TextContainer>
        </Stack>
      </Card>

      <Card sectioned>
        <Stack vertical spacing="tight">
          <TextContainer>
            <Heading>Delivery Information</Heading>
            <p>
              <strong>Carrier:</strong>{" "}
              {
                carrierOptions.find(
                  (option) => option.value === formValues.carrier_provider
                )?.label
              }
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {formValues.delivery_date instanceof Date
                ? formValues.delivery_date.toLocaleDateString()
                : formValues.delivery_date}
            </p>
            <p>
              <strong>Time Window:</strong> {formValues.start_time} -{" "}
              {currentEndTime}
            </p>
            <p>
              <strong>Order Value:</strong> $
              {parseFloat(formValues.total_value_cents || 0).toFixed(2)}
            </p>
            {formValues.items_notes && (
              <p>
                <strong>Notes:</strong> {formValues.items_notes}
              </p>
            )}
          </TextContainer>
        </Stack>
      </Card>

      <Card sectioned>
        <Stack vertical spacing="tight">
          <TextContainer>
            <Heading>Delivery Rate</Heading>
            <p>
              <strong>Delivery Fee:</strong> ${deliveryRate?.amount.toFixed(2)}
            </p>
            {deliveryRate?.details && (
              <>
                <p>
                  <strong>Service:</strong>{" "}
                  {deliveryRate.details.service_name || "Standard Delivery"}
                </p>
                {deliveryRate.details.delivery_date && (
                  <p>
                    <strong>Estimated Delivery Date:</strong>{" "}
                    {new Date(
                      deliveryRate.details.delivery_date
                    ).toLocaleDateString()}
                  </p>
                )}
                {deliveryRate.location && (
                  <p>
                    <strong>Fulfillment Location:</strong>{" "}
                    {deliveryRate.location.name || "Default Location"}
                  </p>
                )}
              </>
            )}
            <p>
              <TextStyle variation="subdued">
                This is the calculated delivery fee based on your address and
                carrier rates.
              </TextStyle>
            </p>
          </TextContainer>
        </Stack>
      </Card>

      {error && (
        <Banner status="critical">
          <p>{error}</p>
        </Banner>
      )}
    </Stack>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        currentStep === "form" ? "Schedule Manual Delivery" : "Confirm Delivery"
      }
      primaryAction={{
        content:
          currentStep === "form"
            ? "Continue to Confirmation"
            : "Schedule Delivery",
        onAction:
          currentStep === "form"
            ? handleProceedToConfirmation
            : handleCreateAndSchedule,
        loading: currentStep === "form" ? isLoadingRate : isSubmitting,
      }}
      secondaryActions={
        currentStep === "form"
          ? [
              {
                content: "Cancel",
                onAction: onClose,
              },
            ]
          : [
              {
                content: "Back to Form",
                onAction: handleBackToForm,
              },
              {
                content: "Cancel",
                onAction: onClose,
              },
            ]
      }
    >
      <Modal.Section>
        {isLoadingRate && currentStep === "form" ? (
          <div style={{ textAlign: "center", padding: "2rem" }}>
            <Stack vertical alignment="center" spacing="tight">
              <Spinner size="large" />
              <p>Calculating delivery rate...</p>
            </Stack>
          </div>
        ) : currentStep === "form" ? (
          renderFormStep()
        ) : (
          renderConfirmationStep()
        )}
      </Modal.Section>
    </Modal>
  );
}
