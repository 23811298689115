import {
  ActionList,
  Badge,
  Button,
  Icon,
  Popover,
  ResourceItem,
  ResourceList,
  Spinner,
  Stack,
  Subheading,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { HorizontalDotsMinor } from "@shopify/polaris-icons";
import { useAdmin } from "context/admin";
import { useUser } from "context/user";
import React, { useCallback, useState } from "react";
import {
  deleteManualDelivery,
  scheduleManualDelivery,
  updateManualDelivery,
} from "util/manualDeliveries";

export default function ManualDeliveryList({
  deliveries,
  isLoading,
  onRefresh,
}) {
  const admin = useAdmin();
  const user = useUser();
  const isAdmin = user && user.isAdmin;
  const [activeActionId, setActiveActionId] = useState(null);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastError, setToastError] = useState(false);

  // Group deliveries by status (Today, Upcoming, Past)
  const groupedDeliveries = {
    today: [],
    upcoming: [],
    past: [],
  };

  // Sort and group deliveries if they exist
  if (deliveries && deliveries.length > 0) {
    deliveries.forEach((delivery) => {
      const status = delivery.delivery_status || "upcoming";

      if (status === "Today") {
        groupedDeliveries.today.push(delivery);
      } else if (status === "Upcoming") {
        groupedDeliveries.upcoming.push(delivery);
      } else if (status === "Past") {
        groupedDeliveries.past.push(delivery);
      }
    });

    // Sort each group
    // Today and upcoming: sort by start_time
    groupedDeliveries.today.sort((a, b) =>
      a.start_time < b.start_time ? -1 : 1
    );

    groupedDeliveries.upcoming.sort((a, b) => {
      // First by date
      if (a.delivery_date !== b.delivery_date) {
        return a.delivery_date < b.delivery_date ? -1 : 1;
      }
      // Then by time
      return a.start_time < b.start_time ? -1 : 1;
    });

    // Past: sort by date descending
    groupedDeliveries.past.sort((a, b) => {
      // First by date (descending)
      if (a.delivery_date !== b.delivery_date) {
        return a.delivery_date > b.delivery_date ? -1 : 1;
      }
      // Then by time
      return a.start_time < b.start_time ? -1 : 1;
    });
  }

  const toggleActionMenu = useCallback(
    (id) => {
      setActiveActionId(activeActionId === id ? null : id);
    },
    [activeActionId]
  );

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this delivery?")) {
      try {
        await deleteManualDelivery(admin.selectedShopId, id);
        onRefresh();
        showToast("Delivery deleted successfully", false);
      } catch (error) {
        console.error("Error deleting delivery:", error);
        showToast("Error deleting delivery", true);
      }
    }
    setActiveActionId(null);
  };

  const handleStatusUpdate = async (id, status) => {
    // For cancellation, show a confirmation dialog
    if (status === "cancelled") {
      if (
        !window.confirm(
          "Are you sure you want to cancel this delivery? This will also cancel any scheduled carrier delivery."
        )
      ) {
        setActiveActionId(null);
        return;
      }
    }

    try {
      await updateManualDelivery(admin.selectedShopId, id, {
        status,
        cancel_delivery: status === "cancelled",
      });
      onRefresh();
      showToast(`Delivery marked as ${status}`, false);
    } catch (error) {
      console.error("Error updating delivery status:", error);
      showToast("Error updating delivery status", true);
    }
    setActiveActionId(null);
  };

  const handleScheduleDelivery = async (id) => {
    try {
      setActiveActionId(null); // Close the action menu

      // Show a loading toast
      showToast("Scheduling delivery...", false, true);

      const response = await scheduleManualDelivery(admin.selectedShopId, id);
      console.log("Schedule response:", response);

      // Force a complete refresh of the data to get the latest status
      await onRefresh();

      showToast("Delivery scheduled successfully", false);
    } catch (error) {
      console.error("Error scheduling delivery:", error);

      // Show a more detailed error message
      const errorMessage = error.message || "Error scheduling delivery";
      showToast(errorMessage, true);
    }
  };

  const showToast = (content, isError = false, isLoading = false) => {
    setToastContent(content);
    setToastError(isError);
    setToastActive(true);

    // If it's a loading toast, don't auto-dismiss
    if (!isLoading) {
      // Auto-dismiss after 5 seconds
      setTimeout(() => {
        setToastActive(false);
      }, 5000);
    }
  };

  const toggleToast = useCallback(
    () => setToastActive((active) => !active),
    []
  );

  const renderStatusBadge = (status) => {
    let color = "new";

    switch (status) {
      case "pending":
        color = "attention";
        break;
      case "delivering":
        color = "info";
        break;
      case "delivered":
        color = "success";
        break;
      case "cancelled":
        color = "critical";
        break;
      default:
        color = "new";
    }

    return (
      <Badge status={color}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  const renderDeliveryStatusBadge = (status) => {
    let color = "new";

    switch (status) {
      case "Today":
        color = "success";
        break;
      case "Upcoming":
        color = "info";
        break;
      case "Past":
        color = "subdued";
        break;
      default:
        color = "new";
    }

    return <Badge status={color}>{status}</Badge>;
  };

  const formatMoney = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No date provided";

    try {
      // Parse the date string in format YYYY-MM-DD
      const [year, month, day] = dateString.split("-").map(Number);
      const date = new Date(year, month - 1, day); // month is 0-indexed in JS Date

      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "Invalid date";
      }

      return date.toLocaleDateString();
    } catch (error) {
      console.error("Error formatting date:", error, dateString);
      return "Date error";
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return "";

    console.log(
      `Formatting time string: "${timeString}" (type: ${typeof timeString})`
    );

    try {
      let hours, minutes;

      // Check if the timeString is in ISO format (contains 'T' and possibly 'Z')
      if (typeof timeString === "string" && timeString.includes("T")) {
        // Parse ISO date-time string
        const date = new Date(timeString);
        if (isNaN(date.getTime())) {
          console.error("Invalid ISO time:", timeString);
          return timeString; // Return the original string if parsing fails
        }

        hours = date.getHours();
        minutes = date.getMinutes();
        console.log(`Parsed ISO time: ${hours}:${minutes}`);
      } else if (typeof timeString === "string" && timeString.includes(":")) {
        // Parse simple time format like "14:00" or "14:00:00"
        const parts = timeString.split(":");
        hours = parseInt(parts[0], 10);
        minutes = parseInt(parts[1], 10);

        // Validate the parsed values
        if (
          isNaN(hours) ||
          isNaN(minutes) ||
          hours < 0 ||
          hours > 23 ||
          minutes < 0 ||
          minutes > 59
        ) {
          console.error("Invalid time components:", hours, minutes);
          return timeString;
        }

        console.log(`Parsed simple time: ${hours}:${minutes}`);
      } else {
        console.error("Unrecognized time format:", timeString);
        return String(timeString); // Convert to string and return
      }

      // Format directly without creating a Date object to avoid timezone issues
      const period = hours >= 12 ? "PM" : "AM";
      const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
      const displayMinutes = minutes.toString().padStart(2, "0");
      const formatted = `${displayHours}:${displayMinutes} ${period}`;

      console.log(`Formatted time: "${formatted}"`);
      return formatted;
    } catch (error) {
      console.error("Error formatting time:", error, timeString);
      return String(timeString); // Return the original string if an error occurs
    }
  };

  const formatAddress = (extra) => {
    if (!extra) return "No address provided";

    const addressDetails = extra.address_details || {};

    if (addressDetails.address1) {
      const parts = [
        addressDetails.address1,
        addressDetails.city,
        addressDetails.province,
        addressDetails.postal_code,
      ].filter(Boolean);

      return parts.join(", ");
    }

    return extra.address || "No address provided";
  };

  // Check if a delivery needs scheduling (has no associated delivery or is canceled)
  const needsScheduling = (delivery) => {
    // Check if there's an associated delivery record
    const hasDeliveryRecord =
      delivery.extra && delivery.extra.has_delivery_record;

    // Get the delivery record status if available
    const deliveryRecordStatus =
      hasDeliveryRecord &&
      delivery.extra.delivery_record &&
      delivery.extra.delivery_record.status;

    // Show the schedule button if:
    // 1. There's no delivery record, or
    // 2. The manual delivery status is cancelled, or
    // 3. The delivery record status is cancelled
    // 4. The delivery record is missing (might have been destroyed)
    return (
      !hasDeliveryRecord ||
      delivery.status === "cancelled" ||
      deliveryRecordStatus === "cancelled" ||
      (hasDeliveryRecord && !delivery.extra.delivery_record)
    );
  };

  if (isLoading) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "2rem" }}
      >
        <Spinner size="large" />
      </div>
    );
  }

  if (!deliveries || deliveries.length === 0) {
    return (
      <div style={{ padding: "2rem", textAlign: "center" }}>
        <TextStyle variation="subdued">No manual deliveries found</TextStyle>
      </div>
    );
  }

  // Render a section of deliveries with a heading
  const renderDeliverySection = (title, deliveryList, emptyMessage) => {
    if (!deliveryList || deliveryList.length === 0) {
      return (
        <div style={{ marginBottom: "20px" }}>
          <div style={{ backgroundColor: "#f4f6f8", padding: "12px 24px" }}>
            <Subheading>{title}</Subheading>
          </div>
          <div style={{ padding: "1rem", textAlign: "center" }}>
            <TextStyle variation="subdued">{emptyMessage}</TextStyle>
          </div>
        </div>
      );
    }

    return (
      <div style={{ marginBottom: "20px" }}>
        <div style={{ backgroundColor: "#f4f6f8", padding: "12px 24px" }}>
          <Subheading>{title}</Subheading>
        </div>
        <ResourceList
          items={deliveryList}
          renderItem={(delivery) => {
            const extra = delivery.extra || {};
            const customerName = extra.customer_name || "No name provided";
            const customerPhone = extra.customer_phone || "No phone provided";
            const address = formatAddress(extra);
            const windowName = extra.window_name || null;
            const canSchedule = needsScheduling(delivery);

            // Get delivery record information if available
            const hasDeliveryRecord = extra.has_delivery_record || false;
            const deliveryRecord = extra.delivery_record || null;

            return (
              <ResourceItem
                id={delivery.id}
                accessibilityLabel={`Manual delivery on ${delivery.delivery_date}`}
              >
                <Stack distribution="equalSpacing">
                  <Stack vertical spacing="tight">
                    <TextStyle variation="strong">
                      {customerName} -{" "}
                      {formatMoney(delivery.total_value_cents / 100)}
                    </TextStyle>
                    <TextStyle variation="subdued">ID: {delivery.id}</TextStyle>
                    <TextStyle variation="subdued">{customerPhone}</TextStyle>
                    <TextStyle variation="subdued">{address}</TextStyle>
                    <div style={{ marginTop: "8px" }}>
                      {renderStatusBadge(delivery.status)}
                      {delivery.delivery_status && (
                        <span style={{ marginLeft: "8px" }}>
                          {renderDeliveryStatusBadge(delivery.delivery_status)}
                        </span>
                      )}
                      {/* Only show carrier status if the manual delivery is not cancelled */}
                      {hasDeliveryRecord &&
                        deliveryRecord &&
                        delivery.status !== "cancelled" && (
                          <span style={{ marginLeft: "8px" }}>
                            <TextStyle variation="subdued">Carrier:</TextStyle>{" "}
                            {renderStatusBadge(deliveryRecord.status)}
                          </span>
                        )}
                    </div>
                    {hasDeliveryRecord &&
                      deliveryRecord &&
                      delivery.status !== "cancelled" && (
                        <TextStyle variation="subdued">
                          Provider: {deliveryRecord.shipping_provider} (ID:{" "}
                          {deliveryRecord.id})
                        </TextStyle>
                      )}
                  </Stack>

                  <Stack vertical spacing="tight" alignment="trailing">
                    <TextStyle variation="strong">
                      {formatDate(delivery.delivery_date)}
                    </TextStyle>
                    <TextStyle variation="subdued">
                      {windowName
                        ? windowName
                        : delivery.formatted_delivery_window ||
                          (delivery.start_time && delivery.end_time
                            ? `${formatTime(
                                delivery.start_time
                              )} - ${formatTime(delivery.end_time)}`
                            : "Time not specified")}
                    </TextStyle>
                    <div>
                      <Popover
                        active={activeActionId === delivery.id}
                        activator={
                          <Button
                            onClick={() => toggleActionMenu(delivery.id)}
                            icon={<Icon source={HorizontalDotsMinor} />}
                            plain
                          />
                        }
                        onClose={() => setActiveActionId(null)}
                      >
                        <ActionList
                          items={[
                            ...(canSchedule
                              ? [
                                  {
                                    content: "Schedule Delivery",
                                    onAction: () =>
                                      handleScheduleDelivery(delivery.id),
                                  },
                                ]
                              : []),
                            ...(delivery.status === "pending"
                              ? [
                                  {
                                    content: "Mark as Delivering",
                                    onAction: () =>
                                      handleStatusUpdate(
                                        delivery.id,
                                        "delivering"
                                      ),
                                  },
                                  {
                                    content: "Cancel Delivery",
                                    destructive: true,
                                    onAction: () =>
                                      handleStatusUpdate(
                                        delivery.id,
                                        "cancelled"
                                      ),
                                  },
                                ]
                              : []),
                            ...(delivery.status === "delivering"
                              ? [
                                  {
                                    content: "Mark as Delivered",
                                    onAction: () =>
                                      handleStatusUpdate(
                                        delivery.id,
                                        "delivered"
                                      ),
                                  },
                                  {
                                    content: "Cancel Delivery",
                                    destructive: true,
                                    onAction: () =>
                                      handleStatusUpdate(
                                        delivery.id,
                                        "cancelled"
                                      ),
                                  },
                                ]
                              : []),
                            ...(delivery.status === "delivered" ? [] : []),
                            ...(delivery.status === "cancelled"
                              ? [
                                  {
                                    content: "Mark as Pending",
                                    onAction: () =>
                                      handleStatusUpdate(
                                        delivery.id,
                                        "pending"
                                      ),
                                  },
                                ]
                              : []),
                            ...(isAdmin
                              ? [
                                  {
                                    content: "Delete Delivery",
                                    destructive: true,
                                    onAction: () => handleDelete(delivery.id),
                                  },
                                ]
                              : []),
                          ]}
                        />
                      </Popover>
                    </div>
                  </Stack>
                </Stack>
              </ResourceItem>
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      {renderDeliverySection(
        "Today's Deliveries",
        groupedDeliveries.today,
        "No deliveries scheduled for today"
      )}
      {renderDeliverySection(
        "Upcoming Deliveries",
        groupedDeliveries.upcoming,
        "No upcoming deliveries"
      )}
      {renderDeliverySection(
        "Past Deliveries",
        groupedDeliveries.past,
        "No past deliveries"
      )}

      {toastActive && (
        <Toast
          content={toastContent}
          error={toastError}
          onDismiss={toggleToast}
        />
      )}
    </>
  );
}
