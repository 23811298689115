import config from "config";
import { deleteRequest, fetcher, postData, putData } from "./fetch";

export const fetchManualDeliveries = async (shopId, startDate, endDate) => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);

  const response = await fetcher(
    `/api/shops/${shopId}/manual_deliveries?${params.toString()}`
  );
  return response.json();
};

export const createManualDelivery = async (shopId, deliveryData) => {
  console.log("Creating manual delivery with data:", deliveryData);
  console.log("Shop ID:", shopId);

  try {
    // Wrap the data in a manual_delivery object as expected by the controller
    const response = await postData(`/api/shops/${shopId}/manual_deliveries`, {
      manual_delivery: deliveryData,
    });

    console.log("Response status:", response.status);
    const responseData = await response.json();
    console.log("Response data:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error in createManualDelivery:", error);
    throw error;
  }
};

export const updateManualDelivery = async (
  shopId,
  deliveryId,
  deliveryData
) => {
  console.log("Updating manual delivery with data:", deliveryData);

  const response = await putData(
    `/api/shops/${shopId}/manual_deliveries/${deliveryId}`,
    {
      manual_delivery: deliveryData,
    }
  );
  return response.json();
};

export const deleteManualDelivery = async (shopId, deliveryId) => {
  return deleteRequest(`/api/shops/${shopId}/manual_deliveries/${deliveryId}`);
};

export const scheduleManualDelivery = async (shopId, deliveryId) => {
  try {
    console.log(`Scheduling manual delivery ${deliveryId} for shop ${shopId}`);

    const response = await postData(
      `/api/shops/${shopId}/manual_deliveries/${deliveryId}/schedule`,
      {}
    );

    console.log(`Schedule API response status: ${response.status}`);

    // Parse the response JSON regardless of status code
    const responseData = await response.json();
    console.log("Schedule API response data:", responseData);

    if (!response.ok) {
      // Extract the error message from the response
      const errorMessage = responseData.error || "Failed to schedule delivery";
      console.error(`Error scheduling delivery: ${errorMessage}`);
      throw new Error(errorMessage);
    }

    // Return both the delivery and manual_delivery from the response
    return responseData;
  } catch (error) {
    console.error("Error scheduling manual delivery:", error);
    throw error;
  }
};

export const exportManualDeliveries = (shopId, startDate, endDate) => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);

  window.location.href = `${
    config.apiBaseUri
  }/api/shops/${shopId}/manual_deliveries/export?${params.toString()}`;
};

export const fetchServiceWindowsForManualDelivery = async (shopId) => {
  try {
    const response = await fetch(
      `/api/shops/${shopId}/service_windows?for_manual_delivery=true`
    );
    if (!response.ok) {
      throw new Error(
        `Failed to fetch service windows: ${response.statusText}`
      );
    }
    const data = await response.json();
    return data.available_windows || [];
  } catch (error) {
    console.error("Error fetching service windows for manual delivery:", error);
    return [];
  }
};

export const calculateDeliveryRate = async (shopId, deliveryData) => {
  try {
    console.log("Calculating delivery rate with data:", deliveryData);

    const response = await postData(
      `/api/shops/${shopId}/manual_deliveries/calculate_rate`,
      {
        delivery: deliveryData,
      }
    );

    const responseData = await response.json();
    console.log("Rate calculation response:", responseData);

    if (!response.ok) {
      throw new Error(
        responseData.error || "Failed to calculate delivery rate"
      );
    }

    return responseData;
  } catch (error) {
    console.error("Error calculating delivery rate:", error);
    throw error;
  }
};
