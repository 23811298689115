import {
  Button,
  ButtonGroup,
  Card,
  DatePicker,
  Filters,
  Layout,
  Page,
  Popover,
  Tag,
} from "@shopify/polaris";
import { useAdmin } from "context/admin";
import React, { useCallback, useEffect, useState } from "react";
import {
  exportManualDeliveries,
  fetchManualDeliveries,
} from "util/manualDeliveries";
import ManualDeliveryList from "./components/ManualDeliveryList";
import ManualDeliveryModal from "./components/ManualDeliveryModal";

export default function ManualDeliveriesPage() {
  const admin = useAdmin();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manualDeliveries, setManualDeliveries] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(new Date().setDate(new Date().getDate() + 7)),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [datePickerActive, setDatePickerActive] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const toggleDatePicker = useCallback(
    () => setDatePickerActive((active) => !active),
    []
  );

  const handleSearchChange = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue("");
  }, []);

  // Disable ESLint for all functions involved in the circular dependency

  /* eslint-disable react-hooks/exhaustive-deps */
  const updateAppliedFilters = useCallback((statuses, dates) => {
    const filters = [];

    // Add status filters
    statuses.forEach((status) => {
      filters.push({
        key: `status-${status}`,
        label: `Status: ${status}`,
        onRemove: () => handleStatusChange(status),
      });
    });

    // Add date filter if not default
    const today = new Date();
    const defaultEndDate = new Date(today);
    defaultEndDate.setDate(today.getDate() + 7);

    const isDefaultDateRange =
      dates.start.toDateString() === today.toDateString() &&
      dates.end.toDateString() === defaultEndDate.toDateString();

    if (!isDefaultDateRange) {
      filters.push({
        key: "date-range",
        label: `Date: ${dates.start.toLocaleDateString()} to ${dates.end.toLocaleDateString()}`,
        onRemove: handleClearDateFilter,
      });
    }

    setAppliedFilters(filters);
  }, []);

  const handleStatusChange = useCallback(
    (status) => {
      const newSelectedStatuses = [...selectedStatuses];
      const statusIndex = newSelectedStatuses.indexOf(status);

      if (statusIndex >= 0) {
        newSelectedStatuses.splice(statusIndex, 1);
      } else {
        newSelectedStatuses.push(status);
      }

      setSelectedStatuses(newSelectedStatuses);
      updateAppliedFilters(newSelectedStatuses, dateRange);
    },
    [selectedStatuses, dateRange, updateAppliedFilters]
  );

  const handleClearDateFilter = useCallback(() => {
    const newDateRange = {
      start: new Date(),
      end: new Date(new Date().setDate(new Date().getDate() + 30)),
    };
    setDateRange(newDateRange);
    updateAppliedFilters(selectedStatuses, newDateRange);
  }, [selectedStatuses, updateAppliedFilters]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleClearAll = useCallback(() => {
    setSearchValue("");
    setSelectedStatuses([]);
    const newDateRange = {
      start: new Date(),
      end: new Date(new Date().setDate(new Date().getDate() + 30)),
    };
    setDateRange(newDateRange);
    setAppliedFilters([]);
  }, []);

  const loadDeliveries = useCallback(async () => {
    if (!admin.selectedShopId) return;

    setIsLoading(true);
    try {
      const startDate = dateRange.start.toISOString().split("T")[0];
      const endDate = dateRange.end.toISOString().split("T")[0];

      const deliveries = await fetchManualDeliveries(
        admin.selectedShopId,
        startDate,
        endDate
      );

      console.log(
        "Loaded deliveries with status:",
        deliveries.map((d) => ({
          id: d.id,
          date: d.delivery_date,
          status: d.status,
          delivery_status: d.delivery_status,
          is_upcoming: d.is_upcoming,
        }))
      );

      // Apply client-side filtering for status and search
      let filteredDeliveries = deliveries;

      // Filter by status if any are selected
      if (selectedStatuses.length > 0) {
        filteredDeliveries = filteredDeliveries.filter((delivery) =>
          selectedStatuses.includes(delivery.status)
        );
      }

      // Filter by search term
      if (searchValue) {
        const searchLower = searchValue.toLowerCase();
        filteredDeliveries = filteredDeliveries.filter(
          (delivery) =>
            delivery.items_notes &&
            delivery.items_notes.toLowerCase().includes(searchLower)
        );
      }

      // The API now returns deliveries already sorted by the server
      // No need for additional client-side sorting
      setManualDeliveries(filteredDeliveries);
    } catch (error) {
      console.error("Error loading manual deliveries:", error);
    } finally {
      setIsLoading(false);
    }
  }, [admin.selectedShopId, dateRange, selectedStatuses, searchValue]);

  const applyFilters = useCallback(() => {
    loadDeliveries();
    updateAppliedFilters(selectedStatuses, dateRange);
    setDatePickerActive(false);
  }, [loadDeliveries, updateAppliedFilters, selectedStatuses, dateRange]);

  const handleExportCSV = async () => {
    if (!admin.selectedShopId) return;

    try {
      const startDate = dateRange.start.toISOString().split("T")[0];
      const endDate = dateRange.end.toISOString().split("T")[0];

      exportManualDeliveries(admin.selectedShopId, startDate, endDate);
    } catch (error) {
      console.error("Error exporting manual deliveries:", error);
    }
  };

  useEffect(() => {
    if (admin.selectedShopId) {
      loadDeliveries();
    }
  }, [admin.selectedShopId, loadDeliveries]);

  // Set default date range to show deliveries for the next 30 days instead of just 7
  useEffect(() => {
    const newDateRange = {
      start: new Date(),
      end: new Date(new Date().setDate(new Date().getDate() + 30)),
    };
    setDateRange(newDateRange);
    setAppliedFilters([]);
  }, []);

  const statusFilterOptions = [
    { label: "Pending", value: "pending" },
    { label: "Delivering", value: "delivering" },
    { label: "Delivered", value: "delivered" },
    { label: "Cancelled", value: "cancelled" },
  ];

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <Popover active={false} activator={<div />} onClose={() => {}}>
          <div style={{ padding: "12px" }}>
            {statusFilterOptions.map(({ label, value }) => (
              <Tag
                key={value}
                onClick={() => handleStatusChange(value)}
                selected={selectedStatuses.includes(value)}
                onRemove={
                  selectedStatuses.includes(value)
                    ? () => handleStatusChange(value)
                    : undefined
                }
              >
                {label}
              </Tag>
            ))}
          </div>
        </Popover>
      ),
      shortcut: true,
    },
    {
      key: "date_range",
      label: "Date range",
      filter: (
        <Popover
          active={datePickerActive}
          activator={
            <Button onClick={toggleDatePicker} disclosure>
              {dateRange.start.toLocaleDateString()} -{" "}
              {dateRange.end.toLocaleDateString()}
            </Button>
          }
          onClose={toggleDatePicker}
        >
          <div style={{ padding: "16px" }}>
            <DatePicker
              month={dateRange.start.getMonth()}
              year={dateRange.start.getFullYear()}
              onChange={handleDateRangeChange}
              selected={{
                start: dateRange.start,
                end: dateRange.end,
              }}
              allowRange
            />
            <div style={{ marginTop: "16px", textAlign: "right" }}>
              <ButtonGroup>
                <Button onClick={toggleDatePicker}>Cancel</Button>
                <Button primary onClick={applyFilters}>
                  Apply
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </Popover>
      ),
      shortcut: true,
    },
  ];

  return (
    <Page
      title="Manual Deliveries"
      primaryAction={
        <Button primary onClick={handleModalOpen}>
          Schedule Manual Delivery
        </Button>
      }
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <Filters
                queryValue={searchValue}
                queryPlaceholder="Search by notes..."
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={handleSearchChange}
                onQueryClear={handleSearchClear}
                onClearAll={handleClearAll}
                onQueryBlur={applyFilters}
              >
                <div style={{ marginLeft: "8px" }}>
                  <ButtonGroup>
                    <Button onClick={applyFilters}>Apply Filters</Button>
                    <Button onClick={handleExportCSV}>Export CSV</Button>
                  </ButtonGroup>
                </div>
              </Filters>
            </Card.Section>
            <ManualDeliveryList
              deliveries={manualDeliveries}
              isLoading={isLoading}
              onRefresh={loadDeliveries}
            />
          </Card>
        </Layout.Section>
      </Layout>

      <ManualDeliveryModal
        open={isModalOpen}
        onClose={handleModalClose}
        onSuccess={loadDeliveries}
      />
    </Page>
  );
}
