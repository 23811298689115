import React from "react";
import { useHistory } from "react-router-dom";

import {
  // Receipt as ReceiptIcon,
  Settings as SettingsIcons,
} from "@material-ui/icons";
import { Navigation, SkeletonBodyText } from "@shopify/polaris";
import { CaretDownMinor, ConversationMinor } from "@shopify/polaris-icons";

import { useTheme } from "@material-ui/core";
import { useAdmin } from "context/admin";
import { useUser } from "context/user";

function ShopLinks({ shops, selectedShopId, handleNavigate, user }) {
  const theme = useTheme();

  let navbarLinks = [
    {
      label: "Orders",
      icon: SettingsIcons,
      onClick: () => handleNavigate(`/shops/${selectedShopId}/orders`),
    },
  ];

  if (user && !user.isVendor) {
    navbarLinks = [
      ...navbarLinks,
      {
        label: "Deliveries",
        icon: SettingsIcons,
        onClick: () => handleNavigate(`/shops/${selectedShopId}/deliveries`),
      },
      {
        label: "Settings",
        icon: SettingsIcons,
        onClick: () => handleNavigate(`/shops/${selectedShopId}/settings`),
      },
    ];
  }

  if (user && (user.manualDeliveryAccess || user.isAdmin)) {
    navbarLinks = [
      ...navbarLinks,
      {
        label: "Manual Deliveries",
        icon: SettingsIcons,
        onClick: () =>
          handleNavigate(`/shops/${selectedShopId}/manual-deliveries`),
      },
    ];
  }

  if (user && (user.importAccess || user.isAdmin)) {
    navbarLinks = [
      ...navbarLinks,
      {
        label: "File Imports",
        icon: SettingsIcons,
        onClick: () => handleNavigate(`/shops/${selectedShopId}/file-imports`),
      },
    ];
  }

  if (user && user.isAdmin) {
    navbarLinks = [
      ...navbarLinks,
      {
        label: "Users",
        icon: SettingsIcons,
        onClick: () => handleNavigate(`/shops/${selectedShopId}/users`),
      },
      {
        label: "Sales",
        icon: SettingsIcons,
        onClick: () => handleNavigate(`/shops/${selectedShopId}/sales`),
      },
    ];
  }

  return shops.map((shop) =>
    selectedShopId === shop.id ? (
      <Navigation.Section
        key={shop.current.name}
        title={
          <span style={{ color: theme.palette.primary.main }}>
            {shop.current.name}
          </span>
        }
        items={navbarLinks}
      />
    ) : (
      <Navigation.Section
        key={shop.current.name}
        title={shop.current.name}
        items={[]}
        data-cy="navbarBtn"
        action={{
          icon: CaretDownMinor,
          accessibilityLabel: "View Shop",
          onClick: () => handleNavigate(`/shops/${shop.id}`),
        }}
      />
    )
  );
}

export default function DashboardSidebar() {
  const admin = useAdmin();
  const user = useUser();
  const history = useHistory();

  // For Shop filter
  // const [shopFilter, setShopFilter] = useState();
  // {user && user.isAdmin ?
  //   <TextField
  //     label="Filter Shops"
  //     value={shopFilter}
  //     onChange={(value) => setShopFilter(value)}
  //     clearButton
  //     onClearButtonClick={() => setShopFilter('')}
  //   />
  // : ''}

  const navigate = (path) => {
    history.push(path);
    const sidebarCloseBtn = document.querySelector(
      '.Polaris-Frame__NavigationDismiss[tabindex="0"]'
    );
    if (sidebarCloseBtn) {
      sidebarCloseBtn.click();
    }
  };

  const shopLinks = admin.shops ? (
    <ShopLinks
      shops={admin.shops}
      selectedShopId={admin.selectedShop && admin.selectedShop.id}
      handleNavigate={navigate}
      user={user}
    />
  ) : (
    ""
  );

  const adminLinks =
    user && user.isAdmin ? (
      <Navigation.Section
        separator
        title="Admin"
        items={[
          {
            label: "Dashboard",
            icon: SettingsIcons,
            onClick: () => navigate(`/admin/dashboard`),
          },
          {
            label: "Deliveries",
            icon: SettingsIcons,
            onClick: () => navigate(`/admin/deliveries`),
          },
          {
            label: "Manual Deliveries",
            icon: SettingsIcons,
            onClick: () => navigate(`/admin/manual-deliveries`),
          },
          {
            label: "Users",
            icon: SettingsIcons,
            onClick: () => navigate(`/admin/users`),
          },
          {
            label: "Register Store",
            icon: SettingsIcons,
            onClick: () => navigate(`/admin/shops/register`),
          },
        ]}
      />
    ) : (
      ""
    );

  const supportLinks = false ? (
    <Navigation.Section
      separator
      title="Support"
      items={[]}
      action={{
        icon: ConversationMinor,
        accessibilityLabel: "Contact support",
        onClick: () => console.log("Contact support"),
      }}
    />
  ) : (
    ""
  );

  const loadingShops = (
    <div style={{ padding: "2rem" }}>
      <SkeletonBodyText />
    </div>
  );

  return (
    <Navigation location="/">
      {admin.loadingShops ? loadingShops : shopLinks}
      {supportLinks}
      {adminLinks}
    </Navigation>
  );
}
